import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'clsx';
import { CSSProperties } from 'react';
import { TestIds } from '../constants';
import {
  ItemsContainerProps,
  PageTransition,
  PaginatedGridGallerySkinProps,
} from '../PaginatedGridGallery.types';
import transitionsStyles from './transitions/transitionStyles';

const getTransitionClass = (
  className: string,
  transitionName: PageTransition['name'],
  isReverse: boolean,
): string =>
  transitionsStyles[transitionName][
    `${className}${isReverse ? 'Reverse' : ''}`
  ];

const isReverse = (transition: PageTransition, row: number, col: number) => {
  return transition.reversedRows[row] || transition.reversedColumns[col];
};

const getReversedIndexes = (length: number): { [index: number]: true } => {
  if (length === 1) {
    return {};
  }
  const reversedIndexes = {} as { [index: number]: true };
  for (let index = 0; index < length; index++) {
    if (Math.random() > 0.5) {
      reversedIndexes[index] = true;
    }
  }

  return reversedIndexes;
};

const getRandomTransition = () => {
  const transitions = [
    'SlideVertical',
    'SlideHorizontal',
    'CrossFade',
    'Shrink',
  ] as Array<PageTransition['name']>;
  return transitions[Math.floor(Math.random() * 4)];
};

const generateTransitionData = (
  transition: PaginatedGridGallerySkinProps['transition'],
  rows: PaginatedGridGallerySkinProps['rows'],
  columns: PaginatedGridGallerySkinProps['columns'],
) => {
  const name = transition === 'Random' ? getRandomTransition() : transition;
  return {
    name,
    reversedRows: name === 'SlideHorizontal' ? getReversedIndexes(rows) : {},
    reversedColumns:
      name === 'SlideVertical' ? getReversedIndexes(columns) : {},
  };
};

const getItemsContainerStyles = (
  rows: PaginatedGridGallerySkinProps['rows'],
  columns: PaginatedGridGallerySkinProps['columns'],
  numberOfItems: number,
): CSSProperties => {
  const totalRows = Math.ceil(numberOfItems / columns);
  const rowsInPage = Math.min(totalRows, rows);

  return {
    gridTemplateRows: Array(rowsInPage).fill('1fr').join(' '),
    gridTemplateColumns: Array(columns).fill('1fr').join(' '),
  };
};

const ItemsContainerNoTransition: React.FC<ItemsContainerProps> = props => {
  const { skinsStyle, rows, columns, isPlaying, currentPage, children } = props;

  const numberOfItemsPerPage = rows * columns;
  const firstItemToShowIndex = currentPage * numberOfItemsPerPage;
  const currentPageItems = React.Children.toArray(children).slice(
    firstItemToShowIndex,
    firstItemToShowIndex + numberOfItemsPerPage,
  );

  return (
    <div
      className={classNames(skinsStyle.itemsContainer)}
      data-testid={TestIds.itemsContainer}
      aria-live={isPlaying ? 'off' : 'polite'}
      style={getItemsContainerStyles(
        rows,
        columns,
        React.Children.count(children),
      )}
    >
      {currentPageItems}
    </div>
  );
};

const ItemsContainerWithTransition: React.FC<ItemsContainerProps> = props => {
  const {
    skinsStyle,
    rows,
    columns,
    transition,
    transitionDuration,
    onTransitionEnd,
    isPlaying,
    currentPage,
    children,
  } = props;

  const numberOfItemsPerPage = rows * columns;
  const firstItemToShowIndex = currentPage * numberOfItemsPerPage;
  const currentPageItems = React.Children.toArray(children).slice(
    firstItemToShowIndex,
    firstItemToShowIndex + numberOfItemsPerPage,
  );
  const initialTransitions = {
    currentPageIndex: currentPage,
    currentTransition: generateTransitionData(transition, rows, columns),
    nextTransition: generateTransitionData(transition, rows, columns),
  };

  const pageTransitionsRef = React.useRef(initialTransitions);

  if (pageTransitionsRef.current.currentPageIndex !== currentPage) {
    pageTransitionsRef.current = {
      currentPageIndex: currentPage,
      currentTransition: pageTransitionsRef.current.nextTransition,
      nextTransition: generateTransitionData(transition, rows, columns),
    };
  }
  const currentTransition = pageTransitionsRef.current.currentTransition;
  const nextTransition = pageTransitionsRef.current.nextTransition;

  const transitionTimeoutMs =
    transitionDuration + 100 * Math.max(rows, columns);

  const items = React.Children.map(currentPageItems, (item, indexInPage) => {
    const index = firstItemToShowIndex + indexInPage;
    const rowNumber = Math.floor(indexInPage / columns);
    const columnNumber = indexInPage % columns;
    const enterReverse = isReverse(currentTransition, rowNumber, columnNumber);
    const exitReverse = isReverse(nextTransition, rowNumber, columnNumber);

    return (
      <CSSTransition
        key={`transition-item-${index}`}
        timeout={transitionTimeoutMs}
        onExited={onTransitionEnd}
        unmountOnExit
        classNames={{
          enter: getTransitionClass(
            'enter',
            currentTransition.name,
            enterReverse,
          ),
          enterActive: getTransitionClass(
            'enterActive',
            currentTransition.name,
            enterReverse,
          ),
          exit: getTransitionClass('exit', nextTransition.name, exitReverse),
          exitActive: getTransitionClass(
            'exitActive',
            nextTransition.name,
            exitReverse,
          ),
        }}
      >
        {item}
      </CSSTransition>
    );
  });

  return (
    <TransitionGroup
      className={classNames(skinsStyle.itemsContainer)}
      data-testid={TestIds.itemsContainer}
      aria-live={isPlaying ? 'off' : 'polite'}
      style={getItemsContainerStyles(
        rows,
        columns,
        React.Children.count(children),
      )}
    >
      {items}
    </TransitionGroup>
  );
};

const PaginatedGridGalleryItemsContainer: React.FC<
  ItemsContainerProps
> = props => {
  const hasTransition = props.transition !== 'NoTransition';

  return hasTransition ? (
    <ItemsContainerWithTransition {...props} />
  ) : (
    <ItemsContainerNoTransition {...props} />
  );
};

export default PaginatedGridGalleryItemsContainer;
