import { PageTransition } from '../../PaginatedGridGallery.types';
import CrossFade from './CrossFade.scss';
import Shrink from './ShrinkPage.scss';
import SlideHorizontal from './SlideHorizontal.scss';
import SlideVertical from './SlideVertical.scss';

type transitionClasses = Record<string, string>;

const transitionsStyles: Record<PageTransition['name'], transitionClasses> = {
  CrossFade,
  Shrink,
  SlideHorizontal,
  SlideVertical,
  NoTransition: {},
};

export default transitionsStyles;
